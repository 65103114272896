.page-content {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.left-section, .right-section {
    width: 60%;
}

.search-form {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    position: relative; /* Make this relative to position the dropdown */
}

.search-input {
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.search-button {
    background-color: #000050;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.search-button:hover {
    background-color: #000090;
}

.stock-price {
    font-size: 18px;
    text-align: left;
    padding: 10px;
    border-radius: 15px;
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.chart-container {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.options-section {
    margin-top: 20px;
}

.expiry-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.nav-button {
    padding: 10px;
    border-radius: 45px;
    border-color: #000050;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.expiry-item {
    padding: 15px;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 15px;
    transition: background-color 0.3s;
}

.expiry-item:hover {
    background-color: #000050;
    color: white;
}

.expiry-item.selected {
    background-color: #000050;
    color: white;
}

.strike-selection {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 20px;
}

.options-container {
    display: flex;
    margin-top: 20px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.smalloptions-container {
    display: flex;
    flex-direction: column; /* Changed to column */
    margin-top: 20px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.options-column {
    margin-top: 20px;
    border-radius: 20px;
    width: 100%;
    height: 400px; /* Fixed height for the carousel */
    overflow-y: auto;
}

.option-row {
    background-color: #f0f0f0; /* Light gray background */
    display: flex;
    flex-direction: row; /* Changed to column */
    align-items: center; /* Center align items */
    padding: 10px; /* Reduced padding */
    border-radius: 20px;
    text-align: center;
}

.option-row div {
    width: 100%;
    font-size: 14px; /* Adjusted font size */
    text-align: center;
}

.right-section {
    margin-left: 20px;
    width: 9%;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.analytics-container {
    margin-top: 20px;
}

.analytics-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

.analytics-content {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
}

.autocomplete-dropdown {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
}

.autocomplete-item {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-item:hover {
    background-color: #f0f0f0;
}

.option-row {
    background-color: #f0f0f0; /* Light gray background */
    display: flex;
    flex-direction: row; /* Ensure it's row for the columns */
    align-items: center; /* Center align items */
    padding: 10px; /* Reduced padding */
    border-radius: 20px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.option-row:hover {
    background-color: #000050;
    color: white;
}