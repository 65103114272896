.button {
    background-color: #000050; /* Dark blue from the banner */
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #000032; /* Slightly darker blue on hover */
}

.roboto-thin {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}